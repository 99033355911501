import moment from "moment";

export class McpHelper {

  public randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  public isJSON(str) {
    try {
      return (JSON.parse(str) && !!str);
    } catch (e) {
      return false;
    }
  }

  public tieringProgress(progress: number): any {
    // return progress / 10;
    // console.log('progress',progress);
    if (progress <= 0) {
      return { persen: 0, em: 0 };
    } else if (progress >= 0 && progress <= 25) {
      //return { persen: 25, em: 3 };
      return { persen: 100, em: 10 };
    } else if (progress > 25 && progress <= 50) {
      //return { persen: 50, em: 7.5 };
      return { persen: 100, em: 10 };
    } else if (progress > 50 && progress <= 75) {
      //return { persen: 75, em: 10.8 };
      return { persen: 100, em: 10 };
    } else if (progress > 75 && progress <= 100) {
      return { persen: 100, em: 10 };
    }
  }

  onFileInputSingle(event: any, type: any) {
    const file = event.target.files[0];
    const size = Math.round(file.size / Math.pow(1024, 1));
    const mimeType = file.type;
    const mimes = (type === 'photo') ? ['image/jpeg', 'image/png'] : ['application/pdf'];
    const fileInfo = {
      'name': file.name,
      'size': size + ' KB',
      'type': file.type,
      'lastModified': moment(file.lastModified).format('DD-MM-YYYY HH:MM')
    };

    if (size > 5000) {
      return {
        isSuccess: false,
        msg: 'File "' + file.name + '" tidak dapat di unggah, karena lebih dari 5MB',
        fileInfo
      };
    }

    // if (mimeType !== mime) {
    if (!mimes.includes(mimeType)) {
      return {
        isSuccess: false,
        msg: 'Format file tidak sesuai, seharusnya :' + mimes.join(', '),
        fileInfo
      };
    }

    return {
      isSuccess: true,
      msg: 'success',
      fileInfo
    };
  }

  getMonthName(month: number) {
    const months = [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember'
    ];
    return months[month - 1];
  }

  public colorStatus(status: number): string {
    switch (+status) {
      case 1:
        return 'bg-danger';
      case 2:
        return 'bg-warning';
      case 3:
        return 'bg-success';
      case 4:
        return 'bg-primary';
      default:
        return 'bg-secondary';
    }
  }

  public colorPalettes(i: number) {
    const colors = ['#155263', '#ff6f3c', '#ff9a3c', '#ffc93c', '#43ab92', '#a099ff', '#ea4961', '#9bb899', '#f0e449', '#43ab92', '#ff8426', '#c6cd78', '#ec952e', '#65589c', '#065471', '#9bcb3c', '#ffc045', '#3e92a3', '#ff7f50', '#7b77ff', '#44558f', '#1eb2a6', '#ff9c91', '#1fad9f', '#c5d86d'];
    return colors[i] ?? '#260c1a';
  }

  /**
 * Converts a number of bytes into a human-readable file size.
 *
 * @param {number} bytes - The number of bytes to convert.
 * @param {boolean} [si=false] - Whether to use SI units (kB, MB, etc.) or binary units (KiB, MiB, etc.).
 * @param {number} [dp=1] - The number of decimal places to round the result to.
 * @return {string} The human-readable file size.
 */
  public humanFileSize(bytes, si = false, dp = 1, unit = true) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    if (unit) {
      return bytes.toFixed(dp) + ' ' + units[u];
    } else {
      return bytes.toFixed(dp);
    }
  }
}
