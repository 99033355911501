// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v0.0.1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: false,
  apiUrl: 'https://api.maritimecloud.ai',
  // apiUrl: 'https://api.mcp.id',
  appThemeName: 'MCP',
  maptiler: {
    /*apiKey: 'twWrMpr2zRWdbGXw3vH8',*/  // free
    apiKey: 'X6joOBrI9dkVrLRQaFKM',      // subscription
    //style: 'https://api.maptiler.com/maps/e6c141e9-e59d-4ddd-b657-0101d8c39fbf/style.json?key=twWrMpr2zRWdbGXw3vH8'
    style: 'https://api.maptiler.com/maps/8e5bef56-dd77-42e7-9907-20d4accf66a1/style.json?key=X6joOBrI9dkVrLRQaFKM'
  },
  nextcloud: {
    apiUrl: 'https://nextcloud.besok.org/sso',
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoiY29uZmlyZSIsImEiOiJjbG42ZDYwZDkwOTUyMnJtaGd2djRscTJ2In0.w-kR0l4cjCv-pNkXspqB0Q'
  },
  weatherlayers: {
    accessToken: 'AXRyKvzaq44RhUUEfATB',
    catalogUrl: 'https://catalog.weatherlayers.com/catalog/search',
    version: '2024.7.0',
    collections: {
      wind: 'gfs/wind_10m_above_ground'
    }
  },
  openweathermap: {
    apiUrl: 'https://api.openweathermap.org/data/2.5/weather',
    appid: '94027623d0b9bdf60bed7718d7eb1833',
    iconUrl: 'https://openweathermap.org/img/w'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
