import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private router: Router
        // private authHttpService: AuthHTTPService,
    ) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newReq = req.clone();
        // const token = this._authService.getAuthFromLocalStorage();

        // // if (token && !AuthUtils.isTokenExpired(token)) {
        // if (token != null) {
        //     newReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token?.access_token) });
        // }

        return next.handle(newReq).pipe(
            tap(() => { },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        console.log('intercept', err);
                        if (err.status !== 403) {
                            return;
                        }
                        localStorage.removeItem(this.authLocalStorageToken);
                        document.location.reload();
                    }
                })
        );
    }
}
